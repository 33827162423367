<script setup lang="ts">
import { useForm } from 'vee-validate'
import { z } from 'zod'

import ModalFooter from '~/components/shared/modal/footer.vue'
import VTextInput from '~/components/ui/Inputs/VText.vue'

import { useSegmentBucketList } from '~/composables/useSegmentBucketList'

import { useInfluencersStore } from '~/stores/influencers'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserFavoritesStore } from '~/stores/userFavorites'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type Bucket from '~/types/bucket'

type Props = {
  hasCancel?: boolean
  influencers?: number[]
}

type Emits = {
  cancel: []
  close: [
    payload?: {
      bucket: Bucket
      wasInTheBucket: boolean
      addedInfluencersCount: number
    },
  ]
}

const props = withDefaults(defineProps<Props>(), {
  hasCancel: false,
  influencers: () => [],
})

const emit = defineEmits<Emits>()

const { t } = useI18n()
const route = useRoute()
const { id: AGENCY_ID } = storeToRefs(useUserAgencyStore())
const { trackListCreationFinalized, trackListSaveFinalized } =
  useSegmentBucketList()
const { GET_BY_IDS_SORTED: GET_INFLUENCERS_SORTED_BY_IDS } =
  useInfluencersStore()
const { ADD_BUCKETS } = useUserFavoritesStore()

const nameFieldName = 'name'

// form validation
const { validate: validateForm } = useForm<{
  name: string
}>()

// form field
const { value: nameFieldValue, errorMessage: nameFieldErrorMessage } =
  useFieldValidation<string>(
    nameFieldName,
    z.string().min(1, t('inputs.invalid_required')),
    { syncVModel: true, validateOnValueUpdate: false, initialValue: '' },
  )

const loading = ref(false)

function cancel(): void {
  // Legacy event - kept for now
  trackEvent(
    {
      category: 'Bucket Management',
      action: 'List Creation Cancelled',
    },
    route,
  )
  emit('cancel')
}

async function createBucket(): Promise<void> {
  loading.value = true

  // validate the form with vee-validate's function
  const { valid } = await validateForm()
  if (!valid) {
    loading.value = false
    return
  }

  try {
    const bucket = await $coreFetch.$post<Bucket>(
      '/agency/favorites/',
      {
        influencers: props.influencers,
        name: !nameFieldValue.value.length ? 'Default' : nameFieldValue.value,
        agency: AGENCY_ID.value,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    loading.value = false
    ADD_BUCKETS([bucket])
    trackListCreationFinalized(bucket.name)
    trackListSaveFinalized(
      GET_INFLUENCERS_SORTED_BY_IDS(props.influencers),
      0,
      bucket.influencers.length,
      bucket.name,
    )
    emit('close', {
      bucket,
      wasInTheBucket: false,
      addedInfluencersCount: bucket.influencers.length,
    })
  } catch (_) {
    loading.value = false
  }
}
</script>

<template>
  <div class="tw-relative tw-grid tw-p-4 tw-pb-0 md:tw-p-6">
    <div class="content">
      <VTextInput
        v-model="nameFieldValue"
        :name="nameFieldName"
        data-test-id="agencyFavoritesBucketCreatorNameTextInput"
        :label="$t('agency.favorites.bucketCreator01')"
        :placeholder="$t('agency.favorites.bucketCreator02')"
        :p-validity="!nameFieldErrorMessage"
        @enter="createBucket"
      >
        <template #err-message>
          <p>{{ nameFieldErrorMessage }}</p>
        </template>
      </VTextInput>
    </div>
    <ModalFooter
      :disabled="!nameFieldValue?.length"
      :has-cancel="hasCancel"
      @next="createBucket"
      @cancel="cancel"
    >
      <template #next>
        <span
          class="tw-hidden tw-w-full md:tw-block"
          data-test-id="agencyFavoritesBucketCreatorConfirmCTA"
        >
          {{ $t('common.save') }}
        </span>
        <span class="tw-block tw-w-full md:tw-hidden">
          {{ $t('agency.favorites.saveToBucket') }}
        </span>
      </template>
    </ModalFooter>
  </div>
</template>
